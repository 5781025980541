import request from '@/utils/request'

// mcn--1头部达人 2热门达人 3爆款视频 4广告爆款达人
export function getMcnBanner(data) {
  return request({
    url: '/web/mcn/getMcnBanner',
    method: 'get',
    params: data
  })
}
// mcn--达人招募
export function liveJoin(data) {
  return request({
    url: '/web/mcn/talentJoin',
    method: 'POST',
    data
  })
}
// mcn--商务招募
export function shopJoin(data) {
  return request({
    url: '/web/mcn/shopJoin',
    method: 'POST',
    data
  })
}
// mcn--广告爆款达人
export function getMcnHotLive(data) {
  return request({
    url: '/web/mcn/getMcnHotLive',
    method: 'GET',
    data
  })
}

export function ContentData(data) {
  return request({
    url: '/web/localLive/getContentData',
    method: 'GET',
    params: data
  })
}

export function businessCooperate(data) {
  return request({
    url: '/web/localLive/businessCooperate',
    method: 'post',
    data
  })
}

export function businessSchoolCooperate(data) {
  return request({
    url: '/web/businessSchool/businessCooperate',
    method: 'post',
    data
  })
}

export function businessSchoolContentData(data) {
  return request({
    url: '/web/businessSchool/getContentData',
    method: 'GET',
    params: data
  })
}
