<template>
  <div id='game'>
    <div class='banner_1'>
      <div class='swiper_content'>
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url && logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null'
             target='_blank'><img :src='logoList[0].img' /></a>
        </div>
      </div>
    </div>
    <div class='banner_introduction'>
      <div class='box_introduction' style="position: relative">
        <div class='box_introduction_title'>
          <div class='introduction_title'>华星商学院</div>
<!--          <img src='../assets/home/logo-img.png'/>-->
        </div>
        <div class='introduction_content'>
          <div class='paragraphs'>
            <div>{{textJson.length>0 ? textJson[0].content : ''}}</div>
          </div>
          <div class='paragraphs_img'>
            <img v-if='textJson.length>0 && textJson[0].img' :src='textJson[0].img'/>
<!--            <img src='../assets/critical2.png'/>-->
          </div>
        </div>
      </div>
    </div>
    <div class='brand_wrapper'>
      <div class='brand_title'>讲师阵容</div>
      <div class='brand_content'>
        <vue-seamless-scroll :data="brand_list" :class-option="defaultOption"  class="seamless-warp2">
          <ul class="item">
            <li v-for="(item ,index) in brand_list" style='margin-top: 30px' :key='index'>
              <img :src='item.img' />
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <!--   课程类型-->
    <!--    <div class='head-info-item' style='background-color: #F6F6F6'>-->
    <div class='head-info-item padding-info-s'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>课程类型</span>
        </div>
        <div style='position: relative'>
          <div class='game-caseS'>
            <div class='swiper swiper3'>
              <div class='swiper-wrapper' style='height: 100%'>
                <div class='swiper-slide yanxuan-list' v-for='(item, index) in game_case' :key='index' style='height: 100%'>
                  <div class='lists-con'  style='height: 100%'>
                    <img :src='item.img' alt='' >
<!--                    <div class='know_us'>-->
<!--                      <a :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null'-->
<!--                         target='_blank' style='width: 100%'>-->
<!--                      </a>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class='swiper-button-prev swiper-button-left3'></div>
              <div class='swiper-button-next swiper-button-right3'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    课程案例-->
    <div class='head-info-item padding-info-s' style='margin-top: 0px;padding-top: 10px'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>课程案例</span>
        </div>
        <div style='position: relative'>
          <div class='school-case'>
            <div class='swiper swiper4'>
              <div class='swiper-wrapper'>
                <div class='swiper-slide' v-for='(item, index) in courseCases' :key='index'>
                  <a :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null'>
                    <img :src='item.img' alt=''>
<!--                    <div class='know_us'>-->
<!--                      <a :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null'-->
<!--                         target='_blank' style='width: 100%'>-->
<!--                      </a>-->
<!--                    </div>-->
                  </a>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class='swiper-button-prev swiper-button-left4'></div>
              <div class='swiper-button-next swiper-button-right4'></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    游戏主播招募-->
    <div class='recruit-item'>
      <div class='content-s'>
        <div class='form-item-s'>
          <div class='name-d'>商务合作</div>
          <el-form ref='form' :model='form' :rules='rules' label-position='left'>
            <div class='back-s'>
              <el-form-item label='' prop='Roster'>
                <el-input v-model='form.Roster' size='mini' :placeholder="$t('LIVE.Roster')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='city'>
                <el-input v-model='form.city' size='mini' :placeholder="$t('LIVE.city')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='wechat'>
                <el-input v-model='form.wechat' size='mini' :placeholder="$t('LIVE.wechat')"></el-input>
              </el-form-item>
            </div>
            <div class='back-s'>
              <el-form-item label='' prop='tel'>
                <el-input v-model='form.tel' size='mini' :placeholder='$t("LIVE.phone")'></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center;margin-top: 110px'>
              <el-button type='primary' size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import { Logo, index_List } from '@/api/banner'
  import { getGameBanner,} from '@/api/game'
  import { recruitCultureList} from '@/api/recruit'
  import {businessSchoolContentData, businessSchoolCooperate} from '@/api/mcn'


  export default {
    name: 'BusinessSchool',
    components:{
      vueSeamlessScroll
    },
    data() {
      return {
        logoList: [],
        courseCases:[],
        swiperOptions1: {
          loop: true,
          autoplay: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next1',
            prevEl: '.swiper-button-prev.prev1'
          }
        },
        swiperOptions2: {
          // 自动播放
          autoplay: {
            duration: 1000
            // delay: 500
          },
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next1',
            prevEl: '.swiper-button-prev.prev1'
          }
        },
        swiper3: {
          loop: true, // 循环模式选项
          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.swiper-button-right3',
            prevEl: '.swiper-button-prev.swiper-button-left3'
          },
          slidesPerView: 2,
          slidesPerGroup:1,
          spaceBetween: 40, // 在slide之间设置距离（单位px）。
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          initialSlide: 0,
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true//修改swiper的父元素时，自动初始化swiper
        },
        swiper4: {
          loop: true, // 循环模式选项
          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.swiper-button-right4',
            prevEl: '.swiper-button-prev.swiper-button-left4'
          },
          slidesPerView: 1,
          slidesPerGroup:1,
          spaceBetween: -0, // 在slide之间设置距离（单位px）。
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          initialSlide: 0,
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true//修改swiper的父元素时，自动初始化swiper
        },
        form: {
          Roster: '', // 目前所在短视频平台
          city: '', // 擅长游戏
          wechat: '', // 目前粉丝
          tel: '' //联系电话
        },
        rules: {
          Roster: [
            {
              required: true,
              message: this.$t('LIVE.rules_Roster'),
              trigger: 'blur'
            }
          ],
          city: [
            {
              required: true,
              message: this.$t('LIVE.rules_city'),
              trigger: 'blur'
            }
          ],
          wechat: [
            {
              required: true,
              message: this.$t('LIVE.rules_wechat'),
              trigger: 'blur'
            }
          ],
          tel: [
            {
              required: true,
              message: this.$t('LIVE.rules_phone'),
              trigger: 'blur'
            }
          ],
          wechat: [
            {
              required: true,
              message: this.$t('LIVE.rules_wechat'),
              trigger: 'blur'
            }
          ],
          tel: [
            {
              required: true,
              message: this.$t('LIVE.rules_phone'),
              trigger: 'blur'
            }
          ]
        },
        brand_list:[],
        game_talent: [], // 游戏达人
        game_case: [], // 优质案例（游戏商务类）
        defaultOption:{
          step: 0.8, // 数值越大速度滚动越快
          limitMoveNum: 2,
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 2, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          // singleHeight: -0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        },
        textJson:[]
      }
    },
    computed: {

    },
    mounted() {
      this.get_Logo()
      this.get_businessSchoolContentData(1)
      this.get_businessSchoolContentData(2)
      this.get_businessSchoolContentData(3)
      this.get_businessSchoolContentData(4)
      // this.get_game_banner(1) // 游戏达人
      // this.get_game_banner(2) // 近期资讯
      // this.get_game_banner(3) // 优质案例（游戏商务类）
      // this.get_brand()
      let language = localStorage.getItem('locale')
    },
    methods: {
      get_businessSchoolContentData: function(data) {
        businessSchoolContentData({ type: data }).then(resp => {
          if (resp.status == 200) {
            if (data == 1) {
              this.$set(this, 'textJson', resp.data)
            }
            if (data == 2) {
              this.$set(this, 'brand_list', resp.data)
            }
            if (data == 3) {
              this.$set(this, 'game_case', resp.data)
              this.$nextTick(() => {
                let mySwiper3 = new Swiper('.swiper3', this.swiper3)
              })
            }
            if (data == 4) {
              this.$set(this, 'courseCases', resp.data)
              this.$nextTick(() => {
                let mySwiper4 = new Swiper('.swiper4', this.swiper4)
              })
            }
          } else {
            this.$message(resp.data.msg)
          }
        }).catch(err => {
        })
      },
      get_brand: function() {
        recruitCultureList().then(resp => {
          if(resp.status== 200) {
            this.$set(this, 'brand_list', resp.data)
          } else {
            this.$message(resp.msg)
          }
        }).catch(err => {
        })
      },
      get_Logo: function() {
        Logo(11).then(resp => {
          if (resp.status == 200) {
            this.$set(this, 'logoList', resp.data)
          }
        }).catch(error => {

        })
      },
      get_game_banner: function(data) {
        getGameBanner({ type: data }).then(resp => {
          if (resp.status == 200) {
            if (data == 3) {
              this.$set(this, 'game_case', resp.data)
              this.$nextTick(() => {
                let mySwiper3 = new Swiper('.swiper3', this.swiper3)
                let mySwiper4 = new Swiper('.swiper4', this.swiper3)
              })
            }
          } else {
            this.$message(resp.data.msg)
          }
        }).catch(err => {
        })
      },
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data={
              student_name:this.form.Roster,
              city:this.form.city,
              wechat:this.form.wechat,
              phone:this.form.tel,
            }
            businessSchoolCooperate(data).then(resp => {
              if (resp.status == 200) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.form = {
                  Roster:'',
                  city:'',
                  wechat:'',
                  tel:''
                }
              } else {
                this.$message(resp.data.msg)
              }
            }).catch(err => {
            })
          } else {
            return false
          }
        })
      }
    }
  }
</script>

<style scoped lang='scss'>
  .banner_1 {
    width: 100%;
    height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

  .swiper-container {
    width: 100%;
    height: 700px;

  .swiper-wrapper {
    width: 100%;
    height: 700px;

  .swiper-slide {
    width: 100%;
    height: 700px;

  img {
    width: 100%;
    height: 700px;
  }
  }
  }
  }

  img {
    width: 100%;
    height: 700px;
  }
  }
  }

  .head-info-item {
  //display: flex;
    position: relative;
    padding: 30px 0;

  .header-anchor {
    width: 60%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;
  //position: relative;
    line-height: 30px;

  }

  .swiper-slide {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }

  .avatar2 {
    width: 100%;
    height: 100%;
  //box-shadow: 0px 3px 3px 0px rgba(64, 64, 64, 0.1);
    border-radius: 30px;
  }

  .avatar2:hover {
    box-shadow: 0px 0px 7px 0px rgba(60, 18, 7, 0.1500);
  }
  .game-caseS {
    width: 100%;
    margin: auto;
    height: 462px;
    overflow: hidden;
  //background: #D54724;
  //background: #F2F2F2;
  //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
    border-radius: 40px;
  }

  .game-case {
    width: 89.4%;
    margin: auto;
    height: 535px;
    overflow: hidden;
  //background: #D54724;
  //background: #F2F2F2;
  //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
    border-radius: 40px;
  }
  .school-case {
    width: 101%;
    margin: auto;
    height: 346px;
    overflow: hidden;
  //background: #D54724;
  //background: #F2F2F2;
  //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
    border-radius: 40px;
  }
  }

  .padding-info-s {
    /*padding-bottom: 40px*/
  }

  .yanxuan-list {
    width: 100%;
    height: 466px;
    border-radius: 16px;
  }

  .lists-con {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    position: relative;
    border-radius: 16px;
  }

  .yanxuan-avatar {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  .imgs {
    border-radius: 0 0 0 0;
  }

  .yanxuan-name {
    font-size: 20px;
    line-height: 27px;
    padding: 0 26px 20px 26px;
  }

  .case-num-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

  img {
    vertical-align: middle;
    margin-right: 5px;
  }
  }

  .yx-number {
    padding: 0 26px 0 26px;
    line-height: 25px;
  }

  .know_us {
    text-align: left;
    position: absolute;
    z-index: 999;
    bottom: 150px;
    left: 270px
  }

  .know_us .btns {
    background: #FFFFFF;
    color: #D54724 !important;
    padding: 8px 5px;
    font-size: 16px;
    font-family: SourceHanSansCNVF;
    font-weight: 400;
    border-radius: 4px;
    border: none;
  }

  .know_us .btns:hover {
    background-color: #D54724;
    border: none;
    color: #fff !important;
  }

  .recruit-item {
    height: 700px;
    width: 100%;
    background: url("../assets/skill.png") no-repeat;
    background-size:100% 700px;
    position: relative;

  img {
    height: 700px;
    width: 100%;
  }

  .content-s {
  //width: 55%;
    margin: auto;
  //padding-top: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 120px;
    z-index: 2;
    left: 23%;
  }

  .form-item-s {
    width: 283px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 450px;
    padding: 15px;

  .back-s {
  //background: #F19F8A;
  //border-radius: 25px;
  //padding: 0 10px 0 10px;
  }
  }

  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }

  .data-see {
    padding: 36px 20px 75px 20px;
    margin-left: 105px;
  //background: url("../assets/game/rounded-rectangle.png") no-repeat;
  //background-size:100% 336px;
    background: rgba(255, 255, 255, 0.7400);
    box-shadow: 2px 2px 7px 1px rgba(172, 171, 175, 0);
    opacity: 0.97;
    border-radius: 34px;
    width: 536px;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #4A4A4A;
    line-height: 24px;
    letter-spacing: 1.1px;

  h2 {
    font-size: 45px;
    font-family: SourceHanSansSC;
    font-weight: bold;
    color: #D54724;
    line-height: 86px;
    text-align: center;
  }
  }
  }

  .map-container {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    background-color: rgba(213, 71, 36, 0.1);
  }

  .swiper3 {
    width: 1196PX;
    height: 462px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px; /* 设置按钮大小 */
  }
  .swiper4 {
    width: 1200px;
    height: 346px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px; /* 设置按钮大小 */
    .swiper-slide{
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .banner_introduction {
    box-sizing: border-box;
    /*margin-top: 90px;*/
    width: 100%;
    min-height: 350px;
    /*background-color: #F6F6F6;*/
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

  .box_introduction {
    width: 1205px;
    /*height: 490px;*/
    /*padding: 40px 0;*/
  //background-color: rgba(213, 71, 36, 0.1);
  //border-radius: 40px;
    box-sizing: border-box;
  //padding: 0px 40px 60px 40px;
    font-family: SourceHanSansCNVF-Regular;

  .box_introduction_title {
    margin-top: 50px;
    width: 100%;
    position: relative;
    font-family: SourceHanSansCNVF-Regular;
    text-align: center;


  .introduction_title {
    /*width: 120px;*/
    height: 25px;
    font-size: 26px;
    font-family: SourceHanSansCNVF-Regular;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    /*margin-left: calc(50% - 60px);*/
  }

  }

  .introduction_content {
    margin-top: 60px;
    font-size: 18px;
    font-family: SourceHanSansCNVF-Regular;
    font-weight: 600;
    color: #4D4D4D;
    line-height: 28px;

  .paragraphs {
    /*text-indent: 32px;*/
    margin-bottom: 20px;
    font-size: 16px;
    font-family: SourceHanSansCNVF-Regular;
    font-weight: 400;
    color: #4D4D4D;
    line-height: 38px;
    /*word-wrap: break-word;white-space : normal;*/
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    *white-space: normal !important;
    text-align: justify;
  }
  .paragraphs_img{
    width: 100%;
    height: 365px;
    img{
      width: 100%;
      height: 365px;
    }
  }
  pre{
    text-indent: 32px;
    white-space: pre-wrap;           /* css-3 */
    white-space: -moz-pre-wrap;      /* Mozilla, since 1999 */
    white-space: -pre-wrap;          /* Opera 4-6 */
    white-space: -o-pre-wrap;        /* Opera 7 */
    word-wrap: break-word;           /* Internet Explorer 5.5+ */
  }
  }

  }
  }
  .brand_wrapper{
    padding-top: 15px;
    width: 100%;
    background-color: #f5f5f5;
    margin-top: 40px;
  .brand_title{
    text-align: center;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .brand_content{
    width: 100%;
    /*background-color: #fff;*/
    height: 440px;
  .seamless-warp2{
    width: 100%!important;
    overflow: hidden;
  .item{
    width: 100%!important;
  li{
    width: 200px;
    height: 360px;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 8px;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 30px;
  /*.wrapper_img{*/
  /* width: 120px;*/
  /*  height: 120px;*/
  img{
    width: 200px;
    height: 370px;
    border-radius: 8px;
  }
  /*}*/
  }
  }
  }

  }
  }
</style>
